import { fetchWrapper } from './utils';
import { authorization } from './auth.js';

export const loadRequests = async (page, per_page, filter) => {
  try {
    const params = new URLSearchParams({
      //channel: 'b2b|instore',
      page,
      per_page,
      search: filter,
    });
    const response = await fetchWrapper(
      `${process.env.REACT_APP_API_URL}/requests?${params.toString()}`,
      {
        headers: { authorization: authorization() },
      }
    );
    const jsonResponse = await response.json();
    if (!response.ok) throw jsonResponse;
    const { response: requests } = jsonResponse;
    const total = response.headers.get('X-Total');
    return { requests, total: isNaN(total) ? requests.length : total };
  } catch (e) {
    return { requests: [], total: 0 };
  }
};

export const loadRequest = async (id) => {
  const response = await fetchWrapper(
    `${process.env.REACT_APP_API_URL}/requests/${id}`,
    {
      headers: { authorization: authorization() },
    }
  );
  if (!response.ok) {
    throw await response.json();
  }
  const { response: request } = await response.json();
  return request;
};

export const updateRequest = async (clientId, requestId, data) => {
  const response = await fetchWrapper(
    `${process.env.REACT_APP_API_URL}/client/${clientId}/requests/${requestId}`,
    {
      method: 'PATCH',
      headers: { authorization: authorization() },
      body: JSON.stringify(data),
    }
  );

  if (!response.ok) {
    throw await response.json();
  }

  const { success: request } = await response.json();
  return request;
};

export const getCancelStatus = async (id, clienID) => {
  const response = await fetchWrapper(
    `${process.env.REACT_APP_API_URL}/client/${clienID}/requests/${id}/cancel/status`,
    {
      method: 'GET',
      headers: { authorization: authorization() },
    }
  );

  const jsonResponse = await response.json();
  if (!response.ok) {
    throw await jsonResponse;
  }

  return jsonResponse.response;
};

export const cancelRequest = async (id, clienID, reason, comment) => {
  const response = await fetchWrapper(
    `${process.env.REACT_APP_API_URL}/client/${clienID}/requests/${id}/cancel`,
    {
      method: 'POST',
      body: JSON.stringify({ reason, comment }),
      headers: { authorization: authorization() },
    }
  );

  if (!response.ok) {
    throw await response.json();
  }
  const { success: request } = await response.json();
  return request;
};

export const cancelJob = async (id, requestId) => {
  const response = await fetchWrapper(
    `${process.env.REACT_APP_API_URL}/orders/cancel/${id}/jobs/${requestId}`,
    {
      method: 'POST',
      body: JSON.stringify({
        public_reason_key: 'technical_issue',
      }),
      headers: { authorization: authorization() },
    }
  );

  if (!response.ok) {
    throw await response.json();
  }

  const { success: request } = await response.json();

  return request;
};

export const duplicate = async (clientId, id, body) => {
  const response = await fetchWrapper(
    `${process.env.REACT_APP_API_URL}/orders/client/${clientId}/${id}/duplicate`,
    {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: { authorization: authorization() },
    }
  );

  if (!response.ok) {
    throw await response.json();
  }

  const { success: request } = await response.json();
  return request;
};

export const pushRequest = async (id, clienID) => {
  const response = await fetchWrapper(
    `${process.env.REACT_APP_API_URL}/client/${clienID}/requests/${id}/push`,
    {
      method: 'GET',
      headers: { authorization: authorization() },
    }
  );

  if (!response.ok) {
    throw await response.json();
  }

  const { success: request } = await response.json();
  return request;
};

export const validateRequestAddress = async (id, body) => {
  const response = await fetchWrapper(
    `${process.env.REACT_APP_API_URL}/client/${id}/addresses/validate`,
    {
      headers: { authorization: authorization() },
      method: 'POST',
      body: JSON.stringify(body),
    }
  );
  if (!response.ok) {
    throw await response.json();
  }

  const { response: result } = await response.json();
  return result;
};

export const getTimeSlots = async (id, params) => {
  const response = await fetchWrapper(
    `${process.env.REACT_APP_API_URL}/client/${id}/slots/${params.zone}/${params.type}/${params.date}`,
    {
      method: 'GET',
      headers: { authorization: authorization() },
    }
  );

  if (!response) {
    throw new Error('Error getting time slots');
  }

  if (!response.ok) {
    throw await response.json();
  }
  const {
    response: { slots },
  } = await response.json();

  return slots;
};

export const createSlotsConfig = async (body, clientId = '') => {
  const response = await fetchWrapper(
    `${process.env.REACT_APP_API_URL}/slotsConfigs?clientID=${clientId}`,
    {
      headers: { authorization: authorization() },
      method: 'POST',
      body: JSON.stringify({ ...body, ClientAccountId: clientId.trim() }),
    }
  );
  if (!response.ok) {
    throw await response.json();
  }

  const { response: result } = await response.json();
  return result;
};

export const getSlotsConfigs = async (params) => {
  const res = await fetchWrapper(
    `${
      process.env.REACT_APP_API_URL
    }/slotsConfigs?clientId=${params.ClientAccountId.trim()}&channel=${
      params.Channel
    }`,
    {
      method: 'GET',
      headers: { authorization: authorization() },
    }
  );

  if (!res) {
    throw new Error('Error getting slots config');
  }

  if (!res.ok) {
    throw await res.json();
  }
  const { response } = await res.json();

  return response;
};

export const getSlotsConfig = async (params) => {
  console.log(params);
  const res = await fetchWrapper(
    `${process.env.REACT_APP_API_URL}/slotsConfig?day=${params.Day}${
      params.Zone ? '&zone=' + params.Zone : ''
    }${
      params.ClientAccountId ? '&clientId=' + params.ClientAccountId.trim() : ''
    }${params.Channel ? '&channel=' + params.Channel : '&channel=b2c'}${
      params.SlotType ? '&slotType=' + params.SlotType : '&slotType=Standard'
    }`,
    {
      method: 'GET',
      headers: { authorization: authorization() },
    }
  );

  if (!res) {
    throw new Error('Error getting slots config');
  }

  if (!res.ok) {
    throw await res.json();
  }
  const { response } = await res.json();

  return response;
};

export const deleteSlotsConfig = async (zone, clientID = '') => {
  const response = await fetchWrapper(
    `${process.env.REACT_APP_API_URL}/slotsConfigs/${zone}?clientID=${clientID}`,
    {
      headers: { authorization: authorization() },
      method: 'DELETE',
    }
  );
  if (!response.ok) {
    throw await response.json();
  }
};

export const deleteSlotsConfigByClientID = async (clientID) => {
  const response = await fetchWrapper(
    `${process.env.REACT_APP_API_URL}/clientSlotsConfigs/${clientID}`,
    {
      headers: { authorization: authorization() },
      method: 'DELETE',
    }
  );
  if (!response.ok) {
    throw await response.json();
  }
};

export const validatePromoCode = async (orderId, code) => {
  const response = await fetchWrapper(
    `${process.env.REACT_APP_API_URL}/promocode/order/${orderId}/code/${code}/validate`,
    {
      method: 'GET',
      headers: { authorization: authorization() },
    }
  );

  if (!response) {
    throw new Error('Error validating promo code');
  }

  if (!response.ok) {
    throw await response.json();
  }
  const {
    response: { Valid },
  } = await response.json();

  return Valid;
};

export const getClientSlotType = async (clientId) => {
  const response = await fetchWrapper(
    `${process.env.REACT_APP_API_URL}/clients/${clientId}`,
    {
      method: 'GET',
      headers: { authorization: authorization() },
    }
  );

  if (!response.ok) {
    throw await response.json();
  }

  const {
    response: {
      Config: { SlotType },
    },
  } = await response.json();
  return SlotType;
};

export const getSlot = async ({ client, region, date }) => {
  const res = await fetchWrapper(
    `${process.env.REACT_APP_API_URL}/client/${client}/slots/${region}/dropoff/${date}`,
    {
      method: 'GET',
      headers: { authorization: authorization() },
    }
  );

  if (!res) {
    throw new Error('Error getting slots');
  }

  if (!res.ok) {
    throw await res.json();
  }
  const { response } = await res.json();

  return response;
};
